
 .strapiarticle code {
  background-color:hsla(0,0%,78%,.3);
  padding:.15em;
  border-radius:2px
 }

 .strapiarticle blockquote {
  overflow:hidden;
  padding-right:1.5em;
  padding-left:1.5em;
  margin-left:0;
  margin-right:0;
  font-style:italic;
  border-left:5px solid #ccc
 }
 .strapiarticle[dir=rtl] blockquote {
  border-left:0;
  border-right:5px solid #ccc
 }
 
 .strapiarticle .image {
  display:table;
  clear:both;
  text-align:center;
  margin:1em auto
 }
 .strapiarticle .image img {
  display:block;
  margin:0 auto;
  max-width:100%;
  min-width:50px
 }

 .strapiarticle .image>figcaption {
  display:table-caption;
  caption-side:bottom;
  word-break:break-word;
  color:#333;
  background-color:#f7f7f7;
  padding:.6em;
  font-size:.75em;
  outline-offset:-1px
 }

 .strapiarticle .image.image_resized {
  max-width:100%;
  display:block;
  box-sizing:border-box
 }
 .strapiarticle .image.image_resized img {
  width:100%
 }
 .strapiarticle .image.image_resized>figcaption {
  display:block
 }
 .strapiarticle .image-style-side {
  float:right;
  margin-left:var(--ck-image-style-spacing);
  max-width:50%
 }
 .strapiarticle .image-style-align-left {
  float:left;
  margin-right:var(--ck-image-style-spacing)
 }
 .strapiarticle .image-style-align-center {
  margin-left:auto;
  margin-right:auto
 }
 .strapiarticle .image-style-align-right {
  float:right;
  margin-left:var(--ck-image-style-spacing)
 }
 .strapiarticle .todo-list {
  list-style:none
 }
 .strapiarticle .todo-list li {
  margin-bottom:5px
 }
 .strapiarticle .todo-list li .todo-list {
  margin-top:5px
 }
 .strapiarticle .todo-list .todo-list__label>input {
  -webkit-appearance:none;
  display:inline-block;
  position:relative;
  width:var(--ck-todo-list-checkmark-size);
  height:var(--ck-todo-list-checkmark-size);
  vertical-align:middle;
  border:0;
  left:-25px;
  margin-right:-15px;
  right:0;
  margin-left:0
 }
 .strapiarticle .todo-list .todo-list__label>input:before {
  display:block;
  position:absolute;
  box-sizing:border-box;
  content:"";
  width:100%;
  height:100%;
  border:1px solid #333;
  border-radius:2px;
  transition:box-shadow .25s ease-in-out,background .25s ease-in-out,border .25s ease-in-out
 }
 .strapiarticle .todo-list .todo-list__label>input:after {
  display:block;
  position:absolute;
  box-sizing:content-box;
  pointer-events:none;
  content:"";
  left:calc(var(--ck-todo-list-checkmark-size)/3);
  top:calc(var(--ck-todo-list-checkmark-size)/5.3);
  width:calc(var(--ck-todo-list-checkmark-size)/5.3);
  height:calc(var(--ck-todo-list-checkmark-size)/2.6);
  border-left:0 solid transparent;
  border-bottom:calc(var(--ck-todo-list-checkmark-size)/8) solid transparent;
  border-right:calc(var(--ck-todo-list-checkmark-size)/8) solid transparent;
  border-top:0 solid transparent;
  transform:rotate(45deg)
 }
 .strapiarticle .todo-list .todo-list__label>input[checked]:before {
  background:#26ab33;
  border-color:#26ab33
 }
 .strapiarticle .todo-list .todo-list__label>input[checked]:after {
  border-color:#fff
 }
 .strapiarticle .todo-list .todo-list__label .todo-list__label__description {
  vertical-align:middle
 }
 .strapiarticle .media {
  clear:both;
  margin:1em 0;
  display:block;
  min-width:15em
 }

 .strapiarticle .table {
  margin:1em auto;
  display:table
 }
 .strapiarticle .table table {
  border-collapse:collapse;
  border-spacing:0;
  width:100%;
  height:100%;
  border:1px double #b3b3b3
 }
 .strapiarticle .table table td,
 .strapiarticle .table table th {
  min-width:2em;
  padding:.4em;
  border:1px solid #bfbfbf
 }
 .strapiarticle .table table th {
  font-weight:700;
  background:hsla(0,0%,0%,5%)
 }
 .strapiarticle[dir=rtl] .table th {
  text-align:right
 }
 .strapiarticle[dir=ltr] .table th {
  text-align:left
 }

 .strapiarticle .table>figcaption {
  display:table-caption;
  caption-side:top;
  word-break:break-word;
  text-align:center;
  color:var(--ck-color-table-caption-text);
  background-color:var(--ck-color-table-caption-background);
  padding:.6em;
  font-size:.75em;
  outline-offset:-1px
 }
 
 .strapiarticle hr {
  margin:15px 0;
  height:4px;
  background:#dedede;
  border:0
 }
 
 .strapiarticle .raw-html-embed {
  margin:1em auto;
  min-width:15em;
  font-style:normal
 }
 
 .strapiarticle .text-tiny {
  font-size:.7em
 }
 .strapiarticle .text-small {
  font-size:.85em
 }
 .strapiarticle .text-big {
  font-size:1.4em
 }
 .strapiarticle .text-huge {
  font-size:1.8em
 }
 .strapiarticle pre {
  padding:1em;
  color:#353535;
  background:hsla(0,0%,78%,.3);
  border:1px solid #c4c4c4;
  border-radius:2px;
  text-align:left;
  direction:ltr;
  tab-size:4;
  white-space:pre-wrap;
  font-style:normal;
  min-width:200px
 }
 .strapiarticle pre code {
  background:unset;
  padding:0;
  border-radius:0
 }
.strapilink a{
  color:  	#151B8D;
  font-size: 16px;
  font-weight: 400;
}

 